/* Contact Us Page Styles */
.contact-page {
  padding: 50px 20px;
}

.contact-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-page form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.contact-page label {
  margin: 10px 0 5px;
}

.contact-page input,
.contact-page textarea {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-page button {
  background-color: rgb(8, 68, 88);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.contact-page button:hover {
  background-color: #74bb9f;
}

.contact-page button.contact-link {
  background-color: rgb(8, 68, 88); /* Ensure both buttons have the same background color */
  color: white; /* Set text color */
  border: none; /* No border */
  padding: 10px 15px; /* Consistent padding */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
}

.contact-page button.contact-link:hover {
  background-color: #74bb9f; /* Hover effect */
}

@media (max-width: 768px) {
  .contact-page h1 {
    font-size: 2rem;
  }
}


.textAreaStyle {
  width: '300px';
  height: '100px';
  margin-bottom: '10px';
  padding: '10px';
  border-radius: '5px';
  border: '1px solid #ccc';
  font-size: '16px';
};

.buttonStyle {
  padding: '10px 20px';
  background-color: rgb(8, 68, 88);
  color: 'white';
  border: 'none';
  border-radius: '5px';
  cursor: 'pointer';
  font-size: '16px';
};


.error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-weight: bold;
}